import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

const DirectPage: React.FC = () => {
    useEffect(() => {
        // Extract URL parameters
        const params = new URLSearchParams(window.location.search);
        const mode = params.get('mode');
        const oobCode = params.get('oobCode');
        const continueUrl = params.get('continueUrl'); // Optional: Use if needed for redirecting after action

        // Direct users based on the mode parameter
        switch (mode) {
            case 'resetPassword':
                // Navigate to the Reset Password page and pass the oobCode
                navigate(`/auth/reset-password/?oobCode=${oobCode}`);
                break;
            case 'verifyEmail':
                // Navigate to the Email Verification page and pass the oobCode
                navigate(`/auth/confirm-email/?oobCode=${oobCode}`);
                break;
            case 'recoverEmail':
                // Handle email recovery if your app supports it
                break;
            // Add additional cases as needed based on your app's supported Firebase Auth actions
            default:
                // Redirect to the homepage or a 404 page if mode is unrecognized
                navigate('/'); // or navigate('/404/');
                break;
        }
    }, []);

    // Optional: Render a loading indicator or a message while redirecting
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <p>Processing request...</p>
        </div>
    );
};

export default DirectPage;
